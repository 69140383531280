<!--设置管理模块--警报设置编辑页--联系方式选择页-->
<template>
  <div
    id="deviceContact"
    v-loading="loading"
    :element-loading-text="$t('deviceContact.a1')"
    ref="deviceContact"
  >    
    <div class="inquire">
      <a-form-model ref="ruleForm" :model="form">
        <div class="form_row">
          <div class="form_col">
            <a-form-model-item :label="$t('deviceContact.a2')">
              <a-input v-model="form.clientName" />
            </a-form-model-item>
          </div>
          <div class="form_col">
            <a-form-model-item :label="$t('deviceContact.a3')">
              <a-input v-model="form.siteName" />
            </a-form-model-item>
          </div>            
          <div class="form_col">
            <a-form-model-item :label="$t('deviceContact.a4')">
              <a-input v-model="form.contactName" />
            </a-form-model-item>
          </div> 
          <div class="form_col">
            <a-form-model-item :label="$t('deviceContact.a5')">
              <a-input v-model="form.contact1_3" />
            </a-form-model-item>
          </div>          
          <div class="form_col">
            <a-form-model-item>
              <a-button type="primary" @click="inquire">{{$t('deviceContact.a6')}}</a-button>
            </a-form-model-item>
          </div>
        </div>        
      </a-form-model>
    </div>
    <div class="main">
      <my-tabletitle>{{$t('deviceContact.a7')}}</my-tabletitle>
      <a-table
        :row-selection="allrowSelection"
        :columns="columns"
        :dataSource="allList"        
        :pagination="pagination"
      >
      </a-table>     
    </div>
    <div class="detail">
      <a-form-model ref="ruleForm" :model="alterForm" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item :label="$t('deviceContact.a4')">
          <a-input v-model="alterForm.contactName"  style="width: 330px"/>
        </a-form-model-item>
        <a-form-model-item :label="$t('deviceContact.a8')">
          <a-select v-model="alterForm.kind1" style="width: 120px">
            <a-select-option v-for="item in contactKind" :value="item" :key="item">
              {{item}}
            </a-select-option>
          </a-select>
          <a-input v-model="alterForm.contact1" style="margin-left: 10px;width: 200px" />
        </a-form-model-item>
        <a-form-model-item :label="$t('deviceContact.a9')">
          <a-select v-model="alterForm.kind2" style="width: 120px">
            <a-select-option v-for="item in contactKind" :value="item" :key="item">
              {{item}}
            </a-select-option>
          </a-select>
          <a-input v-model="alterForm.contact2" style="margin-left: 10px;width: 200px" />
        </a-form-model-item>
        <a-form-model-item :label="$t('deviceContact.a10')">
          <a-select v-model="alterForm.kind3" style="width: 120px">
            <a-select-option v-for="item in contactKind" :value="item" :key="item">
              {{item}}
            </a-select-option>
          </a-select>
          <a-input v-model="alterForm.contact3" style="margin-left: 10px;width: 200px" />
        </a-form-model-item>    
      </a-form-model>      
    </div>    
    <div class="footer">
      <a-button type="primary" style="margin-left: 10px" @click="Add">{{$t('deviceContact.a11')}}</a-button>
        <a-button type="primary" style="margin-left: 10px" @click="Update">{{$t('deviceContact.a12')}}</a-button>
        <a-button type="primary" style="margin-left: 10px" @click="Delete">{{$t('deviceContact.a13')}}</a-button>
      <a-button type="primary" style="margin-left: 10px" @click="Confirm">{{$t('deviceContact.a14')}}</a-button>
    </div>
  </div>
</template>

<script>
import tabletitle from "../../../../components/Title/tabletitle";
import headerTitle from "../../../../components/Title/headerTitle";
import { getFacilityAllPointList,getContactSetting,delContactData,addContactData,updateContactData}
         from "../../../../api/sysmanage";


export default {  
  props: {
    siteId: {
      type: Number,
    },
  },   
  data() {
    return {
      months:[1,2,3,4,5,6,7,8,9,10,11,12],
      labelCol: { span: 4 },
      wrapperCol: { span: 12 },
      form: {//查询条件
        siteName:"",//建筑物名称
        clientName: "",//客户名称
        contactName:"",//联系方式名称
        contact1_3:"",//联系方式1~3
      },
      alterForm:{//更新联系方式
        contactName:"",//联系方式名称
        contact1:"",//联系方式1
        contact2:"",//联系方式2
        contact3:"",//联系方式3
        kind1:"",//联系方式形式1
        kind2:"",//联系方式形式2
        kind3:"",//联系方式形式3        
      },
      contactKind:[//联系方式形式
          "TEL",
          "FAX",
          "E-MAIL"
       ],
      selectedOptions:[],
      loading:false,
      name: this.$t('deviceContact.a15'),
      type: this.$route.query.type,
      schType: this.$route.query.schType,
      nodeName: this.$route.query.nodeName,
      visible: false,
      confirmLoading: false,
      buttonloading: false,
      loading1: false,
      allList: [],
      addList:[],
      radioValue: 0,
      alterList:[],
      pagination: {
        total: 0, //数据总数
        pageSize: 5, //每页中显示10条数据
        showSizeChanger: true, //是否可以改变pagesize
        pageSizeOptions: ["5", "10", "50", "100"], //每页中显示的数据
        showTotal: (total) => ` ${total} ${this.$t('deviceContact.a16')}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: true, //只有一页时是否隐藏分页：默认值false
      },      
      columns: [
        {
          title: "NO",
          dataIndex: "key",
          ellipsis: true,
          width: 60,
          fixed: "left",
        },
        {
          title: this.$t('deviceContact.a4'),
          dataIndex: "contactName",
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t('deviceContact.a8'),
          dataIndex: "contact1",
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t('deviceContact.a9'),
          dataIndex: "contact2",
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t('deviceContact.a10'),
          dataIndex: "contact3",
          width: 100,
          ellipsis: true,
        },          
      ],
    };
  },
  created(){
    // this.getData();
  },
  mounted() {     
    // this.getData();    
    // this.getAlldata();
  },
   watch: {     
    // 监听全部认证终端数据，对数据进行加工
    // allList: {
      // handler(newName, oldName) {
      //   let registerList = this.registerList;
      //   let allList = JSON.stringify(this.allList);
      //   allList = JSON.parse(allList);
      //   console.log("handler:",allList); 
      //   this.alterallList = allList;
      //   for(let i=0;i<this.alterallList.length;i++){
      //     this.alterallList[i].key=i+1;
      //   }
      // },
      // deep: true,
    // },
  },
  computed: {       
    allrowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          // selectedRows[0].setting = "有"
          this.alterList = selectedRows;
          if(this.alterList!=null&&this.alterList.length>0)
            this.alterForm = this.alterList[0];
        },
        type:"radio"
      };
    },
  },
  methods: {
    // 条件查询方法
    inquire() {     
      let par = {}; 
      console.log("this.form", this.form);
        this.loading = true;         
        par = {
          siteId: this.siteId,
          contactName: this.form.contactName,
          style: this.form.contact1_3,
        };   
        getContactSetting(par)
          .then((res) => {
            this.loading = false;
            let { data } = res;
            console.log("delContactData data", data);            
            this.allList = data.contactList;
            for (let i = 0; i < this.allList.length; i++) {
              this.allList[i].key = i + 1;
            }
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });         
 
    },    
    // 添加
    Add() {
      if(this.allList.length>0){
        for(let i=0;i<this.allList.length;i++){
          if(this.allList[i].contactName==this.alterForm.contactName){
            this.$message.error(this.$t('deviceContact.a24')); 
            return;
          }
        }
      }
      this.addList.length=0;//清空列表
      this.addList.push(this.alterForm);
      let par = {
          siteId: this.siteId,
          contactList:this.addList,
        };   
      console.log("addContactData par", par);
      addContactData(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log("addContactData data", data);
          if(data == "true"){//成功时
            if (this.addList.length > 0) {
              //连接2个数组
              this.allList = this.allList.concat(this.addList);
              for (let i = 0; i < this.allList.length; i++) {
                this.allList[i].key = i + 1;
              }
              this.addList = [];
              this.$message.info(this.$t('deviceContact.d23')); 
            } else {
              this.$message.error(this.$t('deviceContact.a18')); 
            }
            
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });      
    },
    // 删除联系方式
    Delete() {
      let ids = "";//联系方式ID集合，逗号分隔
      if (this.alterList.length > 0) {
        for (let i = 0; i < this.alterList.length; i++) {
          ids = ids+this.alterList[i].id;
          if(i<this.alterList.length-1)
            ids =ids+ ",";
        }
      }  
      let par = {
        param:ids,
      }
      console.log("delContactData par", par);
      delContactData(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log("delContactData data", data);
          if(data == "true"){//删除成功时
            if (this.alterList.length > 0) {
              for (let i = 0; i < this.alterList.length; i++) {
                this.allList = this.allList.filter(
                  (item) => item.dvId !== this.alterList[i].dvId
                );
              }
              this.alterList = [];
            } else {
              this.$error({
                title: this.$t('deviceContact.a19'),
                content: this.$t('deviceContact.a20'),
                centered: true,
              });
            }
            this.$message.info(this.$t('deviceContact.a21'));
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });      
    },    
    Update() {
      if(this.alterList==null||this.alterList.length==0)
        this.$message.error(this.$t('deviceContact.a22')); 
      else{
        console.log("Update",this.alterList);  
        updateContactData(this.alterForm)
          .then((res) => {
            this.loading = false;
            let { data } = res;
            console.log("updateContactData data", data);
            this.$message.info(this.$t('deviceContact.a23'));
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      }
    },
    Confirm(){
      this.$emit("chooseEvent",this.alterList[0]);
    },
  },
  components: {
    "my-headertitle": headerTitle,
    "my-tabletitle": tabletitle,
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#deviceContact {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow: auto;
}
.inquire {
  /* height: 50px; */
  padding: 0px 20px;
  border-bottom: 1px solid rgba(12, 12, 12, 0.1);
  white-space: nowrap;
}
.main {
  width: 100%;
  /* height: calc(100% - 53px); */
  padding: 10px 20px;
  overflow: auto;
}
.detail{
  padding: 10px 20px;
}
.main-button-vessel {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  overflow: auto;
}
.main-button-vessel button {
  margin: 0px 10px;
}
.main-register-vessel {
  overflow: hidden;
}
.form_row {
  display: flex;
  align-items: flex-end;
}
.form_col {
  margin-right: 15px;
  min-width: 1px;
  white-space: nowrap;
}
.name {
  font-size: 15px;
}
.footer {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 20px;
  overflow: hidden;
}
.footer button {
  margin-left: 10px;
}
.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.margin {
  margin-right: 10px;
}
p {
  margin-bottom: 10px;
}
</style>