<!--设置管理模块--警报设置编辑页--设备选择页-->
<template>
  <div
    id="deviceEditor"
    v-loading="loading"
    :element-loading-text="$t('deviceEditor.a1')"
    ref="deviceEditor"
  >    
    <div class="inquire">
      <a-form-model ref="ruleForm" :model="form">
        <div class="form_row">
          <!--设备类型选择框-->
          <div class="form_col">
            <a-form-model-item :label="$t('deviceEditor.a2')">
              <a-cascader :options="options" v-model='form.value' placeholder="Please select" @change="onSelectChange" />
            </a-form-model-item>
          </div>
          <!--设备名称-->
          <div class="form_col" v-show="drShow">
            <a-form-model-item :label="$t('deviceEditor.a3')">
              <a-input v-model="form.name" />
            </a-form-model-item>
          </div>
          <!--设备Gr1-->
          <div class="form_col" v-show="dvShow">
            <a-form-model-item :label="$t('deviceEditor.a4')">
              <a-input v-model="form.Gr1" />
            </a-form-model-item>
          </div>      
          <!--设备Gr2-->      
          <div class="form_col" v-show="dvShow">
            <a-form-model-item :label="$t('deviceEditor.a5')">
              <a-input v-model="form.Gr2" />
            </a-form-model-item>
          </div> 
          <!--设备Gr3-->
          <div class="form_col" v-show="dvShow">
            <a-form-model-item :label="$t('deviceEditor.a6')">
              <a-input v-model="form.Gr3" />
            </a-form-model-item>
          </div>   
          <!--查询按钮-->       
          <div class="form_col">
            <a-form-model-item>
              <a-button type="primary" @click="inquire">{{$t('deviceEditor.a7')}}</a-button>
            </a-form-model-item>
          </div>
        </div>        
      </a-form-model>
    </div>
    <div class="main">
      <!--全部设备列表-->
      <div class="main-all-vessel">
        <my-tabletitle>{{$t('deviceEditor.a8')}}</my-tabletitle>
        <a-table
          :row-selection="allrowSelection"
          :columns="columns"
          :dataSource="alterallList"
          :scroll="{ x: 700 }"
          :pagination="pagination"
        >
        </a-table>
      </div>
      <!--添加删除按钮-->
      <div class="main-button-vessel">
        <a-button type="primary" style="width: 120px" @click="add"
          >↓ {{$t('deviceEditor.a9')}}</a-button
        >
        <a-button type="primary" style="width: 120px" @click="Delete"
          >↑ {{$t('deviceEditor.a10')}}</a-button
        >
      </div>
      <!--选择设备列表-->
      <div class="main-register-vessel">
        <my-tabletitle>{{$t('deviceEditor.a11')}}</my-tabletitle>
        <a-table
          :row-selection="rowSelection"
          :columns="columns"
          :dataSource="registerList"
          :scroll="{ x: 700 }"
          :pagination="pagination"
        >
        </a-table>
      </div>
    </div>
    <div class="footer">
      <!--设置按钮-->
      <a-button
        type="primary"
        style="width: 120px"
        @click="update"
        >{{$t('deviceEditor.a12')}}</a-button
      >
    </div>    
  </div>
</template>

<script>
import tabletitle from "../../../../components/Title/tabletitle";
import headerTitle from "../../../../components/Title/headerTitle";
import { getFacilityAllPointList,getEnterAllPointList,getVideoAllPointList,UpdateregisterList,}
         from "../../../../api/sysmanage";


export default {  
  props: {
    siteId: {
      type: Number,
    },
  },   
  data() {
    return {
      form: {
        value:[],
        // type1:"",//一级分类（设备、门禁、视频）
        // type2: "",//二级分类（视频：摄像机、录像机；门禁：电气锁、自动门、闸机、认证终端、警备、接点、*电梯）
        name: "",//名称
        Gr1:"",
        Gr2:"",
        Gr3:"",
      },
      dvShow:false,
      drShow:false,
      selectedOptions:[],
      loading:false,
      name: this.$t('deviceEditor.a13'),
      type: this.$route.query.type,
      schType: this.$route.query.schType,
      nodeName: this.$route.query.nodeName,
      visible: false,
      confirmLoading: false,
      buttonloading: false,
      loading1: false,
      registerList: [],
      allList: [],
      alterallList: [],
      pointList:[],
      radioValue: 0,
      addList: [],
      deleteList:[],
      pagination: {
        total: 0, //数据总数
        pageSize: 5, //每页中显示10条数据
        showSizeChanger: true, //是否可以改变pagesize
        pageSizeOptions: ["5", "10", "50", "100"], //每页中显示的数据
        showTotal: (total) => ` ${total} ${this.$t('deviceEditor.a14')}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: true, //只有一页时是否隐藏分页：默认值false
      },
      options: [
        {        
          value: 0,
          label: this.$t('deviceEditor.a2'),
          children: [],
        },
        {
          value: 1,
          label: this.$t('deviceEditor.a15'),
          children: [
            {
              value: 0,
              label: this.$t('deviceEditor.a16'),
              children: [],
            },
            {
              value: 1,
              label: this.$t('deviceEditor.a17'),
              children: [],
            },
            {
              value: 2,
              label: this.$t('deviceEditor.a18'),
              children: [],
            },
            {
              value: 3,
              label: this.$t('deviceEditor.a19'),
              children: [],
            },
            {
              value: 4,
              label: this.$t('deviceEditor.a20'),
              children: [],
            },
            {
              value: 5,
              label: this.$t('deviceEditor.a21'),
              children: [],
            },
          ],
        },
        {
          value: 2,
          label: this.$t('deviceEditor.a22'),
          children: [
            {
              value: 0,
              label: this.$t('deviceEditor.a16'),
              children: [],
            },
            {
              value: 1,
              label: this.$t('deviceEditor.a23'),
              children: [],
            },
            {
              value: 2,
              label: this.$t('deviceEditor.a24'),
              children: [],
            },
          ],
        },
      ],
      columns: [
        {
          title: "NO",
          dataIndex: "key",
          ellipsis: true,
          width: 70,
          fixed: "left",
        },
        {
          title: this.$t('deviceEditor.a25'),
          dataIndex: "dvName",
          width: 130,
          ellipsis: true,
        },
        // {
        //   title: "Gr1",
        //   dataIndex: "gr1",
        //   width: 150,
        //   ellipsis: true,
        // },
        // {
        //   title: "Gr2",
        //   dataIndex: "gr2",
        //   width: 150,
        //   ellipsis: true,
        // },
        // {
        //   title: "Gr3",
        //   dataIndex: "gr3",
        //   width: 130,
        //   ellipsis: true,
        // },        
        {
          title: this.$t('deviceEditor.a26'),
          dataIndex: "signalName",
          width: 130,
          ellipsis: true,
        },
        {
          title: this.$t('deviceEditor.a27'),
          dataIndex: "signalType",
          width: 100,
          ellipsis: true,
        },
        {
          title: "TagPoint",
          dataIndex: "tagPoint",
          width: 100,
          ellipsis: true,
        },
      ],
    };
  },
  created(){
    // this.getData();
  },
  mounted() {     
    // this.getData();    
    // this.getAlldata();
  },
   watch: {     
    // 监听全部认证终端数据，对数据进行加工
    allList: {
      handler(newName, oldName) {
        let registerList = this.registerList;
        let allList = JSON.stringify(this.allList);
        allList = JSON.parse(allList);
        for (let i = 0; i < registerList.length; i++) {
          allList = allList.filter((item) => {
            return item.dvId !== registerList[i].dvId;
          });
        }
        console.log("terminalEditor3:",allList); 
        this.alterallList = allList;
        for(let i=0;i<this.alterallList.length;i++){
          this.alterallList[i].key=i+1;
        }
      },
      deep: true,
    },
  },
  computed: {       
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.deleteList = selectedRows;
        },
      };
    },
    allrowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          // selectedRows[0].setting = "有"
          this.addList = selectedRows;
        },
      };
    },
  },
  methods: {
    // 条件查询方法
    inquire() {     
      let par = {}; 
      let sk = "";
      let unitKindName ="";
      console.log("this.form.value", this.form.value);
      console.log("this.selectedOptions", this.selectedOptions);
      switch(this.form.value[0]){
        case 0://设备  
          this.loading = true; 
          if(this.form.Gr1!=null&&this.form.Gr1!="")  {
            sk="Gr1,"+this.form.Gr1;
          }
          if(this.form.Gr2!=null&&this.form.Gr2!="")  {
            if(sk==""){
              sk="Gr2,"+this.form.Gr2;
            }else{
              sk=sk+";Gr2,"+this.form.Gr2;
            }
          }
          if(this.form.Gr2!=null&&this.form.Gr2!="")  {
            if(sk==""){
              sk="Gr3,"+this.form.Gr3;
            }else{
              sk=sk+";Gr3,"+this.form.Gr3;
            }
          }
          par = {
            siteId: this.siteId,
            points: "",
            searchKeyword: sk,
          };   
          getFacilityAllPointList(par)
            .then((res) => {
              this.loading = false;
              let { data } = res;
              console.log("getFacilityAllPointList data", data);
              for (let i = 0; i < data.length; i++) {
                data[i].key = i + 1;
              }
              this.allList = data;
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
            });
          break;
        case 1://门禁
          this.loading = true;               
          if(this.selectedOptions[1].label!=this.$t('deviceEditor.a16'))  {
              unitKindName = this.selectedOptions[1].label;   
              sk=unitKindName+","+this.form.name;
          }
          par = {
            siteId: this.siteId,
            points: "",
            unitKindName:unitKindName,
            searchKeyword: sk,
          };   
          getEnterAllPointList(par)
            .then((res) => {
              this.loading = false;
              let { data } = res;
              console.log("getEnterAllPointList data", data);
              for (let i = 0; i < data.length; i++) {
                data[i].key = i + 1;
              }
              this.allList = data;
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
            });
          break;
        case 2://视频
          this.loading = true;  
          if(this.selectedOptions[1].label!=this.$t('deviceEditor.a16'))  {
              unitKindName = this.selectedOptions[1].label;   
              sk=unitKindName+","+this.form.name;
          }
          par = {
            siteId: this.siteId,
            points: "",
            unitKindName:unitKindName,
            searchKeyword: sk,
          };   
          getVideoAllPointList(par)
            .then((res) => {
              this.loading = false;
              let { data } = res;
              console.log("getVideoAllPointList data", data);
              for (let i = 0; i < data.length; i++) {
                data[i].key = i + 1;
              }
              this.allList = data;
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
            });
          break;
      } 
    },
    onSelectChange(value,selectedOptions) {
      console.log("value:",value);
      console.log("selectedOptions",selectedOptions); 
      this.selectedOptions = selectedOptions;//保存选择项
      if(value!=null&&value.length>0){
        this.dvShow = value[0]==0?true:false;
        this.drShow = !this.dvShow;
      }     
    },      
    // 添加终端
    add() {
      if (this.addList.length > 0) {
        let type = this.addList[0].signalType;  
        if(this.registerList.length>0)
          type = this.registerList[0].signalType; 
        for(let j=0;j<this.addList.length;j++){
          if(type!=this.addList[j].signalType){
            this.$message.error(this.$t('deviceEditor.a32')); 
            return;
          }        
        } 

        for (let i = 0; i < this.addList.length; i++) {
          this.alterallList = this.alterallList.filter((item) => {
            return item.dvId !== this.addList[i].dvId;
          });
        }
        this.registerList = this.registerList.concat(this.addList);
        for (let i = 0; i < this.registerList.length; i++) {
          this.registerList[i].key = i + 1;
        }
        this.addList = [];
      } else {
        this.$error({
          title: this.$t('deviceEditor.a28'),
          content: this.$t('deviceEditor.a29'),
          centered: true,
        });
      }
    },
    // 删除终端
    Delete() {
      if (this.deleteList.length > 0) {
        for (let i = 0; i < this.deleteList.length; i++) {
          this.registerList = this.registerList.filter(
            (item) => item.dvId !== this.deleteList[i].dvId
          );
        }
        this.alterallList = this.alterallList.concat(this.deleteList);
        for(let i=0;i<this.alterallList.length;i++){
          this.alterallList[i].key=i+1;
        }
        this.deleteList = [];
      } else {
        this.$error({
          title: this.$t('deviceEditor.a30'),
          content: this.$t('deviceEditor.a31'),
          centered: true,
        });
      }
    },    
    update() {
      console.log("chooseEvent",this.registerList); 
      this.$emit("chooseEvent",this.registerList);
    },
  },
  components: {
    "my-headertitle": headerTitle,
    "my-tabletitle": tabletitle,
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#deviceEditor {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow: auto;
}
.inquire {
  /* height: 50px; */
  padding: 0px 20px;
  border-bottom: 1px solid rgba(12, 12, 12, 0.1);
  white-space: nowrap;
}
.main {
  width: 100%;
  /* height: calc(100% - 53px); */
  padding: 10px 20px;
  overflow: auto;
}
.main-all-vessel {
  overflow: auto;
}
.main-button-vessel {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  overflow: auto;
}
.main-button-vessel button {
  margin: 0px 10px;
}
.main-register-vessel {
  overflow: hidden;
}
.form_row {
  display: flex;
  align-items: flex-end;
}
.form_col {
  margin-right: 15px;
  min-width: 1px;
  white-space: nowrap;
}
.name {
  font-size: 15px;
}
.footer {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 20px;
  overflow: hidden;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
}
.footer button {
  margin-left: 10px;
}
.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.margin {
  margin-right: 10px;
}
p {
  margin-bottom: 10px;
}
</style>