<!--设置管理模块--警报管理页--警报设置-->
<template>
  <div id="warningSetting" v-loading="loading">
    <div class="header">
      <a-form-model ref="ruleForm" :model="form">
        <div class="form_row">
          <div class="form_col">
            <!--站点组联菜单-->
            <a-form-model-item>
              <a-cascader :options="options" @change="onChange" :placeholder="$t('warningSetting.a1')" @popupVisibleChange="getBaseInfo"/>
            </a-form-model-item>
          </div>           
          <div class="form_col">
            <!--警报类型-->
            <a-form-model-item>
              <a-input v-model="form.alarmType" :placeholder="$t('warningSetting.a2')"/>
            </a-form-model-item>
          </div>
          <div class="form_col">
            <!--负责人-->
            <a-form-model-item>
              <a-input v-model="form.mainUser" :placeholder="$t('warningSetting.a3')"/>
            </a-form-model-item>
          </div>          
          <div class="form_col">
            <!--查询-->
            <a-form-model-item>
              <a-button type="primary" @click="inquire" style="width:120px">{{$t('warningSetting.a4')}}</a-button>
            </a-form-model-item>
          </div>
        </div>
      </a-form-model>  
    </div>
    <div class="main" v-table-size="tableSize"> 
        <!--警报设置信息列表-->
        <a-table 
          :columns="columns" 
          :dataSource="data" 
          :pagination="pagination"
          :scroll="size" 
          :row-selection="rowSelection"
        > 
        </a-table>     
    </div> 
    <!--添加修改删除-->
    <div class="footer">
      <a-button class="but" type="primary" @click="deleteRecord">{{$t('warningSetting.a5')}}</a-button>
      <a-button class="but" type="primary" @click="editRecord">{{$t('warningSetting.a6')}}</a-button>
      <a-button class="but" type="primary" @click="addRecord">{{$t('warningSetting.a7')}}</a-button>
    </div>
    <!--警报设置弹窗-->
    <a-modal
      v-model="visible" 
      :title="name" 
      width=1200px 
      :footer="null"
      centered
      :destroyOnClose="true"
      v-drag
    >
      <my-warningSettingModel :siteId='siteId' :add='add' :warningSetting='warningSetting' @show="onShow"></my-warningSettingModel>
    </a-modal>       
  </div>
</template>

<script>
import tabletitle from "../../../../components/Title/tabletitle";
import headerTitle from "../../../../components/Title/headerTitleA";
import terminalEditor from "./terminalEditor";
import deviceContact from "./deviceContact";
import warningSettingModel from "./warningSettingModel";
import {getWarningSettingList,
        deleteWarningSetting,
        } from "../../../../api/sysmanage";

export default {                                         
  data() {
    return {
      baseInfo: this.$store.getters.oneMenu,
      size: { x: 2000, y: 600 },
      classification:[
        {key:1,title:this.$t('warningSetting.a28')},
        {key:2,title:this.$t('warningSetting.a29')},
        {key:3,title:this.$t('warningSetting.a30')},
        ],
      alertDisp:[
        {key:1,title:this.$t('warningSetting.a32')},
        {key:2,title:this.$t('warningSetting.a33')},
        ],
      alarmActivation:[
        {key:1,title:this.$t('warningSetting.a34')},
        {key:2,title:this.$t('warningSetting.a35')},
        ],
      notificationMail:[
        {key:1,title:this.$t('warningSetting.a34')},
        {key:2,title:this.$t('warningSetting.a35')},
        ],
      toOffNormal:[
        {key:1,title:this.$t('warningSetting.a34')},
        {key:2,title:this.$t('warningSetting.a35')},
        ],
      toNormal:[
        {key:1,title:this.$t('warningSetting.a34')},
        {key:2,title:this.$t('warningSetting.a35')},
        ],
      alarmSoundPattern:[
        {key:1,title:this.$t('warningSetting.a40')},
        {key:2,title:this.$t('warningSetting.a41')},
        {key:3,title:this.$t('warningSetting.a42')},
        {key:4,title:this.$t('warningSetting.a43')},
        ],
      mailUser:[
        {key:1,title:this.$t('warningSetting.a34')},
        {key:2,title:this.$t('warningSetting.a35')},
        ],
      calendars:[],
      name: this.$t('warningSetting.a17'),
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
      visible: false,//警报设置项弹窗是否显示
      dvChooseVisible: false,//设备选择弹窗是否显示
      dvContact1Visible: false,//设备联系方式1选择弹窗是否显示
      dvContact2Visible: false,//设备联系方式2选择弹窗是否显示
      dvContact3Visible: false,//设备联系方式3选择弹窗是否显示  
      dvContact4Visible: false,//设备联系方式4选择弹窗是否显示
      dvContact5Visible: false,//设备联系方式5选择弹窗是否显示
      dvContact6Visible: false,//设备联系方式6选择弹窗是否显示
      dvChooseName:this.$t('warningSetting.a54'),
      dvContactName: this.$t('warningSetting.a55'),
      dvChooseList:[],
      add:false,//添加或变更
      data: undefined,
      loading: false,
      clientId: 0,
      siteGrId: 0,
      siteId:0,
      siteServiceTypeID:0,
      operateList:[],
      selectedOptions:{},
      alarmTypeOption:[],
      form: {
        mainUser: "",
        alarmType: "",
      },
      rules: {
        mainUser: [
          { required: true, message: 'Please input mainUser name', trigger: 'blur' },
        ],        
      },
      warningSetting:{},
      warningSettingInitial:{
        alarmGuidanceWithAttr:{},
        id: "",	
	 	    officeCode: "",//企业代码
        customerCode: "",//客户编辑
        customerName: "",//客户名称
        builName: "",//建筑名称
        pointID: "",//设备信号点ID
        objectType: "device",//警报对象
        equipmentName: "",//设备信号点名称
        equipmentType:0,//设备类型
        type: "",
        category: "101",
        alarmType	: "",        
        description	: "",
        dealPlan	: "",
        mainUser	: "",
        contact1	: "",
        contact2	: "",
        contact3	: "",
        contact0	: "",
        contact1_ID	: "",
        contact1_0	: "",
        contact1_1	: "",
        contact1_2	: "",
        contact1_3	: "",	
        contact2_ID	: "",
        contact2_0	: "",	
        contact2_1	: "",	
        contact2_2	: "",	
        contact2_3	: "",
        contact3_ID	: "",		
        contact3_0	: "",	
        contact3_1	: "",
        contact3_2	: "",
        contact3_3: "",
        contact4_ID	: "",
        contact4_0	: "",
        contact4_1	: "",
        contact4_2	: "",
        contact4_3	: "",
        contact5_ID	: "",
        contact5_0	: "",
        contact5_1		: "",
        contact5_2			: "",
        contact5_3			: "",
        contact6_ID			: "",
        contact6_0		: "",
        contact6_1		: "",
        contact6_2		: "",
        contact6_3		: "",
        notificationMail: "是",
        toOffNormal: "是",	
        toNormal	: "是",
        alarmSoundPattern	: "",		
        distributionPattern : "",
        monitoringSchedule	: "不使用",	
        alertManual		: "",
        classification	: "电话对应",	
        alertDisp		: "显示",
        alarmActivation	: "是",
        updateDate		: "",	
        mailUserId: "",	
        mailUser: "",
        updateUser		: "",
        implementor	: "",
        implementorId	: "",
        soundID: "",
        alarmFile: "",
        scheduleId: "",
        scheduleName: "",
        calendarId: "",
        calendarName: "",
        toMailAddress: "",
        ccMailAddress: "",
        bccMailAddress: "",
      },
      pagination: {
        //分页需求配置
        total: 0, //数据总数
        pageSize: 10, //每页中显示10条数据
        showSizeChanger: true, //是否可以改变pagesize
        pageSizeOptions: ["10", "50", "100"], //每页中显示的数据
        showTotal: (total) => `共 ${total} ${this.$t('warningSetting.a56')}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        // hideOnSinglePage:true,//只有一页时是否隐藏分页：默认值false
      },
      columns: [
        {
          title: "NO",
          dataIndex: "key",
          width: 80,
          // scopedSlots: { customRender: "NO" },
        },
        {
          title: this.$t('warningSetting.a8'),
          dataIndex: "officeCode",
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t('warningSetting.a9'),
          dataIndex: "customerCode",
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t('warningSetting.a10'),
          dataIndex: "customerName",
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t('warningSetting.a11'),
          dataIndex: "builName",
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t('warningSetting.a15'),
          dataIndex: "equipmentName",
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t('warningSetting.a16'),
          dataIndex: "type",
          width: 80,
          ellipsis: true,
        },
        {
          title:this.$t('warningSetting.a2'),
          dataIndex: "alarmType",
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t('warningSetting.a18'),
          dataIndex: "description",
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t('warningSetting.a19'),
          dataIndex: "dealPlan",
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t('warningSetting.a3'),
          dataIndex: "mainUser",
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t('warningSetting.a57'),
          dataIndex: "contact1_0",
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t('warningSetting.a58'),
          dataIndex: "contact1_1",
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t('warningSetting.a59'),
          dataIndex: "contact1_2",
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t('warningSetting.a60'),
          dataIndex: "contact1_3",
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t('warningSetting.a26'),
          dataIndex: "alertManual",
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t('warningSetting.a27'),
          dataIndex: "classification",
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t('warningSetting.a31'),
          dataIndex: "alertDisp",
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t('warningSetting.a33'),
          dataIndex: "alarmActivation",
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t('warningSetting.a36'),
          dataIndex: "notificationMail",
          width: 100,
          ellipsis: true,
        },
        {
          title:this.$t('warningSetting.a39'),
          dataIndex: "alarmFile",
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t('warningSetting.a45'),
          dataIndex: "updateDate",
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t('warningSetting.a46'),
          dataIndex: "updateUser",
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t('warningSetting.a61'),
          dataIndex: "implementor",
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t('warningSetting.a62'),
          dataIndex: "scheduleName",
          width: 100,
          ellipsis: true,
        },
      ],
    };
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          if(selectedRows.length>0){
            this.operateList = selectedRows;
            this.warningSetting = selectedRows[0];
          }
          console.log("equipment selectedRowKeys:", selectedRowKeys);
          console.log("equipment selectedRows:", selectedRows);
        },
      };
    },
    options() {          
      let siteGrList = [];
      let siteList=[];     
      let options = [];     
      console.log("equipment baseInfo:", this.baseInfo);       
      if(this.baseInfo!=null &&this.baseInfo.length>0){
        for(let i=0;i<this.baseInfo.length;i++){
          let client = {
                    value: this.baseInfo[i].id,
                    label: this.baseInfo[i].clientName,
                    children: [],
                  };
          options.push(client);
          siteGrList = this.baseInfo[i].siteGrList; 
          if(siteGrList!=null&&siteGrList.length>0){                       
            for(let j=0;j<siteGrList.length;j++){
              let siteGr = {
                    value: siteGrList[j].id,
                    label: siteGrList[j].name,
                    children: []
                  };
              client.children.push(siteGr);
              siteList= siteGrList[j].siteList;
              if(siteList!=null&&siteList.length>0){
                for(let k=0;k<siteList.length;k++){
                  let site = {
                    value: siteList[k].siteServiceTypeID+","+siteList[k].id,
                    label: siteList[k].name,
                    children: []
                  };
                  siteGr.children.push(site);
                }
              }
            }            
          }          
        }
      }
      console.log("equipment options:", options);
      return options;
    },
  }, 
  methods: {
    // 表格高度计算
    tableSize() {
      const indexDom = document.getElementById("warningSetting");
      if(indexDom){
        const height = indexDom.getElementsByClassName("main")[0].clientHeight;
        const title = indexDom.getElementsByClassName("ant-table-thead")[0].clientHeight;
        const tableHeight = height - title + 32;
        this.size.y = tableHeight;
      }
    },  
    getBaseInfo(value){
      const baseInfo = this.baseInfo
      if(value){
        if(baseInfo.length == 0){
          this.baseInfo = this.$store.getters.oneMenu
        }
      }
    },
    onShow(e){
      this.visible = e;
    },
    onChange(value,selectedOptions) {
      console.log("value:",value);
      if(selectedOptions!=null){
        this.selectedOptions=selectedOptions;
        this.warningSetting.customerName = this.selectedOptions[0].label;
        this.warningSetting.builName=this.selectedOptions[2].label;
      }
      if(value!=null){
        this.clientId = value[0];
        this.siteGrId = value[1];
        let arr = value[2].split(',');
        this.siteServiceTypeID = arr[0]-0;
        this.siteId = arr[1]-0;
        console.log("siteId:",this.siteId);
      }
      
    },
    deleteRecord(){
      let par = {
            siteId: this.siteId,
            warningSettingList: this.operateList,
          };
          deleteWarningSetting(par)
            .then((res) => {
              this.loading = false;
              let { data } = res;
              console.log("deleteWarningSetting res:", data);
              if (res.errorCode == "00") {
                for (let i = 0; i < this.operateList.length; i++) {
                  this.data = this.data.filter((item) => {
                    return item.id !== this.operateList[i].id;
                  });
                }
                this.$message.success(res.msg);
              } else if (res.errorCode == "02") {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
            });
    },
    editRecord(){
      if(this.operateList.length!=1){
        this.$message.error(this.$t('downloadSetting.a24'));
        return;
      }
      this.warningSetting = this.operateList[0];
      this.visible = true;
      this.add = false;
    },    
    addRecord(){
      if(this.siteId!=0){
        this.warningSetting=this.warningSettingInitial;
        this.warningSetting.customerName = this.selectedOptions[0].label;
        this.warningSetting.builName=this.selectedOptions[2].label;
        this.visible = true;
        this.add = true;
      }
      else
        this.$message.error(this.$t('warningSetting.a63'));    
    },

    // 条件查询方法
    inquire() {
      console.log("warningSetting siteId:", this.siteId);      
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let data = {
            siteId: this.siteId,
            alarmType: this.form.alarmType,            
            mainUser:this.form.mainUser,
          };
          getWarningSettingList(data)
            .then((res) => {
              this.loading = false;
              let { data } = res;
              console.log("warningSetting data", data);
              for (let i = 0; i < data.length; i++) {
                data[i].key = i + 1;
              }
              this.data = data;
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
            });
        } else {
          return false;
        }
      });
    },    
  },
  components: {
    "my-tabletitle": tabletitle,
    "my-headertitle": headerTitle,
    "my-terminalEditor": terminalEditor,
    "my-devcieContact":deviceContact,
    "my-warningSettingModel":warningSettingModel,
  },
  mounted() {    
  },
};
</script>

<style scoped>
div /deep/.ant-modal-body{
  padding: 0px;
}
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#warningSetting {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0px 20px;
  position: relative;
  overflow: hidden;
}
.header {
  min-width: 850px;
  overflow: hidden;
}
.main {
  width: 100%;
  height: calc(100% - 116px);
  overflow: auto;
}
.form_row {
  display: flex;
  align-items: flex-end;
}
.form_col {
  margin-right: 15px;
  min-width: 1px;
  white-space: nowrap;
}
.footer {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0px;
  overflow: auto;
}
.but {
  width: 120px;
  margin-left: 15px;
}
</style>