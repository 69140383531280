<!--设置管理模块--警报管理页--警报设置--新增/编辑窗口-->
<template>
  <div id="warningSettingModel">
      <div class="main">
          <!--警报设置页面-->
            <a-divider class="divider" orientation="left">
              {{name}}
            </a-divider>
            <a-form-model 
              class="main_tab"           
              ref="ruleForm"
              :model="warningSetting"
              :rules="rules"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
            >
                <a-row>
                  <!--企业代码-->
                  <a-col :span="8">
                    <a-form-model-item :label="$t('warningSetting.a8')" prop="officeCode">
                      <a-input v-model="warningSetting.officeCode"  />
                    </a-form-model-item>
                  </a-col>
                  <!--客户编号-->
                  <a-col :span="8">
                    <a-form-model-item :label="$t('warningSetting.a9')" prop="customerCode">
                      <a-input v-model="warningSetting.customerCode"  />
                    </a-form-model-item>
                  </a-col>
                  <!--客户名称-->
                  <a-col :span="8">
                    <a-form-model-item :label="$t('warningSetting.a10')" prop="customerName">
                      <a-input v-model="warningSetting.customerName"  />
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row>
                  <!--建筑名称-->
                  <a-col :span="8">
                    <a-form-model-item :label="$t('warningSetting.a11')"  prop="builName">
                      <a-input v-model="warningSetting.builName"  />
                    </a-form-model-item>
                  </a-col>
                  <!--警报对象-->
                  <a-col :span="8">
                    <a-form-model-item :label="$t('warningSetting.a12')" >
                      <a-select v-model="warningSetting.objectType" @change="onAlarmObjectChange" >
                        <a-select-option value="device">
                          {{$t('warningSetting.a13')}}
                        </a-select-option>
                        <a-select-option value="system">
                          {{$t('warningSetting.a14')}}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                    </a-col>
                  <!--设备名称-->
                  <a-col :span="8">
                    <a-form-model-item :label="$t('warningSetting.a15')">
                      <a-form-model-item :style="{ display: 'inline-block', width: 'calc(20% - 12px)' }" prop="dvChooseList">
                        <a-icon v-if="warningSetting.objectType=='device'" v-model = "warningSetting.dvChooseList"  type="select" @click="onDeviceChoose" />
                      </a-form-model-item>
                      <a-form-model-item :style="{ display: 'inline-block', width: 'calc(80% - 12px)' }">
                        <div v-for="item in warningSetting.dvChooseList" :value="item.signalKind" :key="item.key">{{item.signalName}}</div> 
                      </a-form-model-item>
                      <a-modal 
                        v-model="dvChooseVisible" 
                        :title="dvChooseName" 
                        width=800px  
                        :footer="null"            
                        @ok="dvHandleOk"
                      >
                        <my-terminalEditor :siteId="siteId" @chooseEvent="reviceSondata"></my-terminalEditor>
                      </a-modal>                       
                      <!-- <a-list visible="warningSetting.dvChooseList.length>0" size="small" bordered :data-source="warningSetting.dvChooseList">
                        <a-list-item slot="renderItem" slot-scope="item, index">                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  
                          {{ item.signalName }}
                        </a-list-item>                    
                      </a-list> -->
                      
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row>
                  <!--类型-->
                  <a-col :span="8">
                  <a-form-model-item :label="$t('warningSetting.a16')" >
                    <a-input v-model="typeDesc"  />
                  </a-form-model-item>
                  </a-col>
                  <!--警报类型-->
                  <a-col :span="8">
                    <a-form-model-item :label="$t('warningSetting.a2')" >
                      <a-select v-model="warningSetting.alarmType" >
                        <a-select-option v-for="item in alarmTypeOption" :key="item.key">
                          {{ item.title }}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </a-col>
                  <!--声音设置-->
                  <a-col :span="8">
                    <a-form-model-item :label="$t('warningSetting.a39')" >
                      <a-select v-model="warningSetting.alarmSoundPattern">
                        <a-select-option v-for="item in alarmSoundPattern" :key="item.key" :value="item.title">
                          {{ item.title }}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </a-col>                
                </a-row>
                <a-row>
                  <!--说明-->
                  <a-col :span="8">
                    <a-form-model-item :label="$t('warningSetting.a18')" >
                      <a-input v-model="warningSetting.description" />
                    </a-form-model-item>
                  </a-col>
                  <!--处理方法-->
                  <a-col :span="8">
                    <a-form-model-item :label="$t('warningSetting.a19')"  prop="dealPlan">
                      <a-input v-model="warningSetting.dealPlan" />
                    </a-form-model-item>
                  </a-col>
                  <!--负责人-->
                  <a-col :span="8">
                    <a-form-model-item :label="$t('warningSetting.a3')"  prop="mainUser">
                      <a-input v-model="warningSetting.mainUser" />
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row>
                  <!--设备联系方式1-->
                  <a-col :span="8">
                    <a-form-model-item :label="$t('warningSetting.a20')">
                      <a-form-model-item prop="contact1_0">
                        <a-input v-model="warningSetting.contact1_0" >
                          <a-icon slot="addonAfter" type="select" @click="onContact1Click" />
                        </a-input>
                      </a-form-model-item>
                      <a-modal 
                        v-model="dvContact1Visible" 
                        :title="dvContactName"                       
                        width=800px  
                        :footer="null"            
                        @ok="dvContactHandleOk"
                      >
                        <my-devcieContact :siteId="siteId" @chooseEvent="reviceContact1data"></my-devcieContact>
                      </a-modal> 
                      <a-input v-model="warningSetting.contact1_1" />
                      <a-input v-model="warningSetting.contact1_2" />
                      <a-input v-model="warningSetting.contact1_3" />
                    </a-form-model-item>
                  </a-col>
                  <!--设备联系方式2-->
                  <a-col :span="8">
                    <a-form-model-item :label="$t('warningSetting.a21')" >
                      <a-input v-model="warningSetting.contact2_0" >
                        <a-icon slot="addonAfter" type="select" @click="onContact2Click" />
                      </a-input>
                      <a-modal 
                        v-model="dvContact2Visible" 
                        :title="dvContactName" 
                        width=800px  
                        :footer="null"            
                        @ok="dvContactHandleOk"
                      >
                        <my-devcieContact :siteId="siteId" @chooseEvent="reviceContact2data"></my-devcieContact>
                      </a-modal> 
                      <a-input v-model="warningSetting.contact2_1" />
                      <a-input v-model="warningSetting.contact2_2" />
                      <a-input v-model="warningSetting.contact2_3" />
                    </a-form-model-item>
                  </a-col>
                  <!--设备联系方式3-->
                  <a-col :span="8">
                    <a-form-model-item :label="$t('warningSetting.a22')">
                      <a-input v-model="warningSetting.contact3_0" >
                        <a-icon slot="addonAfter" type="select" @click="onContact3Click" />
                      </a-input>
                      <a-modal 
                        v-model="dvContact3Visible" 
                        :title="dvContactName" 
                        width=800px  
                        :footer="null"            
                        @ok="dvContactHandleOk"
                      >
                        <my-devcieContact :siteId="siteId" @chooseEvent="reviceContact3data"></my-devcieContact>
                      </a-modal>
                      <a-input v-model="warningSetting.contact3_1" />
                      <a-input v-model="warningSetting.contact3_2" />
                      <a-input v-model="warningSetting.contact3_3" />
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row>
                  <!--设备联系方式4-->
                  <a-col :span="8">
                    <a-form-model-item :label="$t('warningSetting.a23')" >
                      <a-input v-model="warningSetting.contact4_0" >
                        <a-icon slot="addonAfter" type="select" @click="onContact4Click" />
                      </a-input>
                      <a-modal 
                        v-model="dvContact4Visible" 
                        :title="dvContactName" 
                        width=800px  
                        :footer="null"            
                        @ok="dvContactHandleOk"
                      >
                        <my-devcieContact :siteId="siteId" @chooseEvent="reviceContact4data"></my-devcieContact>
                      </a-modal>
                      <a-input v-model="warningSetting.contact4_1" />
                      <a-input v-model="warningSetting.contact4_2" />
                      <a-input v-model="warningSetting.contact4_3" />
                    </a-form-model-item>
                  </a-col>
                  <!--设备联系方式5-->
                  <a-col :span="8">
                    <a-form-model-item :label="$t('warningSetting.a24')" >
                      <a-input v-model="warningSetting.contact5_0" >
                        <a-icon slot="addonAfter" type="select" @click="onContact5Click" />
                      </a-input>
                      <a-modal 
                        v-model="dvContact5Visible" 
                        :title="dvContactName" 
                        width=800px  
                        :footer="null"            
                        @ok="dvContactHandleOk"
                      >
                        <my-devcieContact :siteId="siteId" @chooseEvent="reviceContact5data"></my-devcieContact>
                      </a-modal>
                      <a-input v-model="warningSetting.contact5_1" />
                      <a-input v-model="warningSetting.contact5_2" />
                      <a-input v-model="warningSetting.contact5_3" />
                    </a-form-model-item>
                  </a-col>
                  <!--设备联系方式6-->
                  <a-col :span="8">
                    <a-form-model-item :label="$t('warningSetting.a25')" >
                      <a-input v-model="warningSetting.contact6_0" >
                        <a-icon slot="addonAfter" type="select" @click="onContact6Click" />
                      </a-input>
                      <a-modal 
                        v-model="dvContact6Visible" 
                        :title="dvContactName"
                        width=800px  
                        :footer="null"            
                        @ok="dvContactHandleOk"
                      >
                        <my-devcieContact :siteId="siteId" @chooseEvent="reviceContact6data"></my-devcieContact>
                      </a-modal>
                      <a-input v-model="warningSetting.contact6_1" />
                      <a-input v-model="warningSetting.contact6_2" />
                      <a-input v-model="warningSetting.contact6_3" />
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row>
                  <!--警报处理手册-->
                  <a-col :span="8">
                  <a-form-model-item :label="$t('warningSetting.a26')" >
                    <a-input v-model="warningSetting.alertManual" />
                  </a-form-model-item>
                  </a-col>
                  <!--对应区分-->
                  <a-col :span="8">
                  <a-form-model-item :label="$t('warningSetting.a27')" >
                    <a-select v-model="warningSetting.classification">
                      <a-select-option v-for="item in classification" :key="item.key" :value="item.title">
                        {{ item.title }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                  </a-col>
                  <!--警报显示-->
                  <a-col :span="8">
                  <a-form-model-item :label="$t('warningSetting.a31')" >
                    <a-select v-model="warningSetting.alertDisp">
                      <a-select-option v-for="item in alertDisp" :key="item.key" :value="item.title">
                        {{ item.title }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row>
                  <!--发送-->
                  <a-col :span="8">
                    <a-form-model-item :label="$t('warningSetting.a33')" >
                      <a-select v-model="warningSetting.alarmActivation" >
                        <a-select-option v-for="item in alarmActivation" :key="item.key" :value="item.title">
                          {{ item.title }}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </a-col>
                  <!--邮件发送-->
                  <a-col :span="8">
                    <a-form-model-item :label="$t('warningSetting.a36')" >
                      <a-select v-model="warningSetting.notificationMail" >
                        <a-select-option v-for="item in notificationMail" :key="item.key" :value="item.title">
                          {{ item.title }}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </a-col>
                  <!--启动时发送-->
                  <a-col :span="8">
                    <a-form-model-item :label="$t('warningSetting.a37')" >
                      <a-select v-model="warningSetting.toOffNormal" >
                        <a-select-option v-for="item in toOffNormal" :key="item.key" :value="item.title">
                          {{ item.title }}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row>
                  <!--恢复时发送-->
                  <a-col :span="8">
                    <a-form-model-item :label="$t('warningSetting.a38')" >
                      <a-select v-model="warningSetting.toNormal" >
                        <a-select-option v-for="item in toNormal" :key="item.key" :value="item.title">
                          {{ item.title }}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </a-col>    
                  <!--更新时间-->            
                  <a-col :span="8">
                    <a-form-model-item :label="$t('warningSetting.a45')">
                      <a-input v-model="warningSetting.updateDate"/>
                    </a-form-model-item>
                  </a-col>
                  <!--更新人员-->
                  <a-col :span="8">
                    <a-form-model-item :label="$t('warningSetting.a46')" >
                      <a-input v-model="warningSetting.updateUser"/>
                    </a-form-model-item>  
                  </a-col>
                </a-row>
              </a-form-model>                
        <!--发送设置页面-->           
        <!-- <a-tab-pane key="2" :tab="$t('warningSetting.a47')">        -->
          <a-divider  class="divider" orientation="left">
            {{$t('warningSetting.a47')}}
          </a-divider>
          <a-form-model
            ref="ruleForm"
            :model="warningSetting"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >            
            <a-row>     
              <!--用户选择-->
              <a-col :span="8">  
                <a-form-model-item :label="$t('warningSetting.a48')" >
                  <a-select v-model="warningSetting.mailUser" :disabled="selectDisable" >
                    <a-select-option v-for="item in users" :key="item.id" :value="item.loginName">
                      {{ item.loginName }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <!--发送时间表-->
              <a-col :span="8">              
                <a-form-model-item :label="$t('warningSetting.a50')" >
                  <a-select v-model="warningSetting.scheduleName" >
                    <a-select-option v-for="item in list" :key="item.key" :value="item.title">
                      {{ item.title }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <!--发送日历-->
              <a-col :span="8">
                <a-form-model-item :label="$t('warningSetting.a51')">
                  <a-select v-model="warningSetting.calendarName" >
                    <a-select-option v-for="item in calendars" :key="item.id" :value="item.name">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>   
              <!--发送邮件地址-->
              <a-col :span="8">  
                <a-form-model-item :label="$t('warningSetting.a64')" >
                  <a-input v-model="warningSetting.toMailAddress" />
                </a-form-model-item>
              </a-col>
              <a-col :span="8">  
                <a-form-model-item :label="$t('warningSetting.a65')" >
                  <a-input v-model="warningSetting.ccMailAddress" />
                </a-form-model-item>
              </a-col>
              <a-col :span="8">  
                <a-form-model-item :label="$t('warningSetting.a66')" >
                  <a-input v-model="warningSetting.bccMailAddress" />
                </a-form-model-item>
              </a-col>
            </a-row> 
          </a-form-model>             
        <!-- </a-tab-pane>       
      </a-tabs>  -->
      
      <a-form-model-item :wrapper-col="{ span: 6, offset: 18 }">    
        <!--设置-->  
        <a-button type="primary" @click="onSubmit">
          {{$t('warningSetting.a52')}}
        </a-button>
        <!--取消-->
        <a-button style="margin-left: 10px;" @click="onCancel">
          {{$t('warningSetting.a53')}}
        </a-button>   
      </a-form-model-item>    
      
    </div>       
  </div>
</template>

<script>
import tabletitle from "../../../../components/Title/tabletitle";
import headerTitle from "../../../../components/Title/headerTitleA";
import terminalEditor from "./terminalEditor";
import deviceContact from "./deviceContact";
import {getWarningSettingList,
        addWarningSetting,
        updateWarningSetting,
        deleteWarningSetting,
        getMailSchedule,
        getCalendars,
        getUserSelectList
        } from "../../../../api/sysmanage";
import moment from "moment";

export default { 
  props: {
    warningSetting:{},
    add:Boolean,
    siteId:Number,
  }, 
  data() {
    return {
      selectDisable:true,
      classification:[
        {key:1,title:this.$t('warningSetting.a28')},
        {key:2,title:this.$t('warningSetting.a29')},
        {key:3,title:this.$t('warningSetting.a30')},
        ],
      alertDisp:[
        {key:1,title:this.$t('warningSetting.a32')},
        {key:2,title:this.$t('warningSetting.a33')},
        ],
      alarmActivation:[
        {key:1,title:this.$t('warningSetting.a34')},
        {key:2,title:this.$t('warningSetting.a35')},
        ],
      notificationMail:[
        {key:1,title:this.$t('warningSetting.a34')},
        {key:2,title:this.$t('warningSetting.a35')},
        ],
      toOffNormal:[
        {key:1,title:this.$t('warningSetting.a34')},
        {key:2,title:this.$t('warningSetting.a35')},
        ],
      toNormal:[
        {key:1,title:this.$t('warningSetting.a34')},
        {key:2,title:this.$t('warningSetting.a35')},
        ],
      alarmSoundPattern:[
        {key:1,title:this.$t('warningSetting.a40')},
        {key:2,title:this.$t('warningSetting.a41')},
        {key:3,title:this.$t('warningSetting.a42')},
        {key:4,title:this.$t('warningSetting.a43')},
        ],
      mailUser:[
        {key:1,title:this.$t('warningSetting.a34')},
        {key:2,title:this.$t('warningSetting.a35')},
        ],
      list:[],
      calendars:[],
      users:[],
      name: this.$t('warningSetting.a17'),
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      visible: false,//警报设置项弹窗是否显示
      dvChooseVisible: false,//设备选择弹窗是否显示
      dvContact1Visible: false,//设备联系方式1选择弹窗是否显示
      dvContact2Visible: false,//设备联系方式2选择弹窗是否显示
      dvContact3Visible: false,//设备联系方式3选择弹窗是否显示  
      dvContact4Visible: false,//设备联系方式4选择弹窗是否显示
      dvContact5Visible: false,//设备联系方式5选择弹窗是否显示
      dvContact6Visible: false,//设备联系方式6选择弹窗是否显示
      dvChooseName:this.$t('warningSetting.a54'),
      dvContactName: this.$t('warningSetting.a55'),
      // dvChooseList:[],
      data: undefined,
      loading: false,
      clientId: 0,
      siteGrId: 0,
      siteServiceTypeID:0,
      operateList:[],
      selectedOptions:{},
      alarmTypeOption:[],
      form: {
        mainUser: "",
        alarmType: "",
      },
      typeDesc:"",
      rules: {
        dvChooseList: [
          { required: true, message: '请选择设备', trigger: 'blur' },  
          {validator:this.validatorChooseName} ,      
        ],  
        dealPlan: [
          { required: true, message: '请输入处理方法', trigger: 'blur' },          
        ],   
        mainUser: [
          { required: true, message: '请输入负责人名称', trigger: 'blur' },          
        ],   
        contact1_0: [
          { required: true, message: '请选择设备联系方式', trigger: 'blur' },  
          {validator:this.validatorContact} ,         
        ],         
      },         
    };
  },
  watched: {    
  
  },  
  methods: {
    // 开始校验规则
    validatorChooseName(rule, value, callback){
      console.log("validatorChooseName value:",value);
      if(this.warningSetting.dvChooseList==null||this.warningSetting.dvChooseList.length==0){
        callback(new Error('没选择设备'));//校验不通过结束当前校验
        return false;
      }else{
          callback();//校验通过，cb必须被调用
      }
    },
    // 开始校验规则
    validatorContact(rule, value, callback){
      console.log("validatorContact value:",value);
      if(value==null||value.length==0){
        callback(new Error('没选择设备联系方式'));//校验不通过结束当前校验
        return false;
      }else{
          callback();//校验通过，cb必须被调用
      }
    },
    //tab页面改变回调
    // callback(){
    // },
    //警报对象变更
    onAlarmObjectChange(value){
      console.log("onAlarmObjectChange value:",value);
      this.alarmTypeOption=[];
      if(value=="system"){           
        this.warningSetting.type = "system";
        this.alarmTypeOption=[
                  {key:0,title:"启动通知"},
                  {key:1,title:"AC断"},
                  {key:2,title:"DC断"},
                  {key:3,title:"DC断预告"},
                ];
      } 
      else
        this.warningSetting.category="101";  
    },

    reviceSondata(data){
        console.log("reviceSondata:",data);
        
        this.warningSetting.dvChooseList =data;
        //根据所选设备区分报警类型
        if(this.warningSetting.dvChooseList!=null&this.warningSetting.dvChooseList.length>0){
          this.typeDesc = this.warningSetting.dvChooseList[0].signalType;
          this.warningSetting.type=this.warningSetting.dvChooseList[0].signalKind;
          //在设备类型为1(FLC门禁)的情况或3(flc电梯（门禁）)的情况下
          if(this.warningSetting.dvChooseList[0].dvType==1||this.warningSetting.dvChooseList[0].dvType==3){
            //1:门-电气锁、2:门-自动门、3:栅栏门、4:读卡器、5:警备、6:接点-DI、7:接点-DI警报、8:接点-DO、9:电梯
            switch (this.warningSetting.dvChooseList[0].signalKind) {
              case "1":
              case "2":
              case "3":
                this.alarmTypeOption=[//门
                  {key:0,title:"门故障"},
                  {key:1,title:"门开超时"},
                  {key:2,title:"门异常开"},
                  {key:3,title:"入侵警报"},
                  {key:4,title:"无循环"},
                ];
                break;
              case "4":
                this.alarmTypeOption=[//人脸终端、读卡器
                  {key:0,title:"门禁终端异常"},
                  {key:1,title:"路由差异"},
                  {key:2,title:"威胁警报"},
                  {key:3,title:"设备异常"},
                  {key:4,title:"使用非法卡"},
                ];
                break;
              case "5":
                this.alarmTypeOption=[//警备
                  {key:0,title:"警戒设置无效"},
                  {key:1,title:"警戒解除无效"},
                  {key:2,title:"无效时间开始"},
                  {key:3,title:"无效时间线束"},
                ];
                break;
              default:
                this.alarmTypeOption=[//火
                  {key:0,title:"入侵警报"},
                  {key:1,title:"无循环"},
                  {key:2,title:"火灾"},
                  {key:3,title:"篡改异常"},
                  {key:4,title:"紧急时刻解锁"},
                ];
                break;
            }            
          }
          else if(this.warningSetting.dvChooseList[0].dvType==2){//设备类型为2(fmc(设备))的情况
            switch (this.warningSetting.dvChooseList[0].signalType) {
              case "输入":
                this.alarmTypeOption=[
                  {key:0,title:"超过上下限范围"},
                  {key:1,title:"通讯异常"},
                ];
                break;
              case "输出":
                this.alarmTypeOption=[
                  {key:0,title:"超过上下限范围"},
                ];
                break;
              case "状态":
                this.alarmTypeOption=[
                  {key:0,title:"累计运行时间警报"},
                  {key:1,title:"连续运行时间警报"},
                  {key:2,title:"累计运行次数警报"},
                ];
                break;
              case "警报":
                this.alarmTypeOption=[
                  {key:0,title:"警报"},
                ];
                break;
              case "启停":
                this.alarmTypeOption=[];
                break;
              default:
                break;
            }            
          }
        }
        this.dvChooseVisible = false;
    },
    reviceContact1data(data){
        console.log("reviceContact1data:",data);
        this.warningSetting.contact1_ID =data.id;
        this.warningSetting.contact1_0 =data.contactName;
        this.warningSetting.contact1_1 =data.contact1;
        this.warningSetting.contact1_2 =data.contact2;
        this.warningSetting.contact1_3 =data.contact3;
        this.dvContact1Visible = false;
    },
    reviceContact2data(data){
        console.log("reviceContact1data:",data);
        this.warningSetting.contact2_ID =data.id;
        this.warningSetting.contact2_0 =data.contactName;
        this.warningSetting.contact2_1 =data.contact1;
        this.warningSetting.contact2_2 =data.contact2;
        this.warningSetting.contact2_3 =data.contact3;
        this.dvContact2Visible = false;
    },
    reviceContact3data(data){
        console.log("reviceContact1data:",data);
        this.warningSetting.contact3_ID =data.id;
        this.warningSetting.contact3_0 =data.contactName;
        this.warningSetting.contact3_1 =data.contact1;
        this.warningSetting.contact3_2 =data.contact2;
        this.warningSetting.contact3_3 =data.contact3;
        this.dvContact3Visible = false;
    },
    reviceContact4data(data){
        console.log("reviceContact1data:",data);
        this.warningSetting.contact4_ID =data.id;
        this.warningSetting.contact4_0 =data.contactName;
        this.warningSetting.contact4_1 =data.contact1;
        this.warningSetting.contact4_2 =data.contact2;
        this.warningSetting.contact4_3 =data.contact3;
        this.dvContact4Visible = false;
    },
    reviceContact5data(data){
        console.log("reviceContact5data:",data);
        this.warningSetting.contact5_ID =data.id;
        this.warningSetting.contact5_0 =data.contactName;
        this.warningSetting.contact5_1 =data.contact1;
        this.warningSetting.contact5_2 =data.contact2;
        this.warningSetting.contact5_3 =data.contact3;
        this.dvContact5Visible = false;
    },
    reviceContact6data(data){
        console.log("reviceContact6data:",data);
        this.warningSetting.contact6_ID =data.id;
        this.warningSetting.contact6_0 =data.contactName;
        this.warningSetting.contact6_1 =data.contact1;
        this.warningSetting.contact6_2 =data.contact2;
        this.warningSetting.contact6_3 =data.contact3;
        this.dvContact6Visible = false;
    },
    onDeviceChoose(){
      this.dvChooseVisible=true;
    },
    onContact1Click(){
      this.dvContact1Visible = true;
    },
    onContact2Click(){
      this.dvContact2Visible = true;
    },
    onContact3Click(){
      this.dvContact3Visible = true;
    },
    onContact4Click(){
      this.dvContact4Visible = true;
    },
    onContact5Click(){
      this.dvContact5Visible = true;
    },
    onContact6Click(){
      this.dvContact6Visible = true;
    },
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) { 
          if(this.warningSetting.type!="system")
              this.warningSetting.category="101";  
          if(this.add){//添加 
            if(this.warningSetting.dvChooseList==null||this.warningSetting.dvChooseList.length==0)
              return;
            let warningSettingList=[];
            this.warningSetting.updateDate = new moment().format("YYYY-MM-DD HH:mm:ss");
            this.warningSetting.updateUser= JSON.parse(this.$store.getters.user).loginName;           
            for(let i=0;i<this.warningSetting.dvChooseList.length;i++){
            //深拷贝
              let temp=JSON.stringify(this.warningSetting);
              let ws= JSON.parse(temp);
              console.log("addWarningSetting ws:", ws);
              ws.pointID = this.warningSetting.dvChooseList[i].signalNo;
              warningSettingList.push(ws);
            }

            let par = {
              siteId: this.siteId,
              warningSettingList: warningSettingList,
            };
            console.log("addWarningSetting par:", par);
            addWarningSetting(par)
              .then((res) => {
                this.loading = false;
                let { data } = res;
                console.log("addWarningSetting res:", data);
                if(data)
                  this.$message.info(this.$t('general.a6')); 
                else
                  this.$message.error(this.$t('general.a7')); 
                
              })
              .catch((err) => {
                this.loading = false;
                console.log(err);
              });
          }
          else{//变更            
            updateWarningSetting(this.warningSetting)
              .then((res) => {
                this.loading = false;
                let { data } = res;
                console.log("updateWarningSetting res:", data);
                if(data)
                  this.$message.info(this.$t('general.a6')); 
                else
                  this.$message.error(this.$t('general.a7'));
              })
              .catch((err) => {
                this.loading = false;
                this.$message.error(this.$t('general.a7'));
              });
          }      
          this.visible = false;
          this.inquire();
        } else {
          this.$message.error(this.$t('general.a7'));
          return false;
        }
      });
    },
    onCancel() {
      this.visible = false;
      this.$emit("show",false);
    },   
    handleOk(e) {
      console.log("handleOk e:",e);
      this.visible = false;
    },
    handleCancel(){
      this.visible = false;
    },
    dvHandleOk(e) {
      console.log("dvHandleOk e:",e);
      this.dvChooseVisible = false;
    },
    dvContactHandleOk(e) {
      console.log("dvContactHandleOk e:",e);
      this.dvContactVisible = false;
    },
    // 条件查询方法
    inquire() {
      console.log("warningSetting siteId:", this.siteId);      
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let data = {
            siteId: this.siteId,
            alarmType: this.form.alarmType,
            mainUser:this.form.mainUser,
          };
          getWarningSettingList(data)
            .then((res) => {
              this.loading = false;
              let { data } = res;
              console.log("warningSetting data", data);
              for (let i = 0; i < data.length; i++) {
                data[i].key = i + 1;
              }
              this.data = data;
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
            });
        } else {
          return false;
        }
      });
    }, 
    //取可选用户列表
    getUserSelectList(){
      let par = {
            siteId: this.siteId+"",
          };
      console.log("warningSetting getUserSelectList par:",par);
      getUserSelectList(par)
      .then((res) => {
          this.spinning = false;
          let { data } = res;
          this.users.length=0;
          if(data!=null){      
            this.users=data;
            console.log("warningSetting getUserSelectList:",this.users);
          }          
          
        })
        .catch((err) => {
          this.spinning = false;
          console.log(err);
        });
    },
    //取发送日历信息
    getCalendars(){
      let par = {
            siteId: this.siteId,
          };
      console.log("warningSetting getCalendars par:",par);
      getCalendars(par)
      .then((res) => {
          this.spinning = false;
          let { data } = res;
          this.calendars.length=0;
          if(data!=null){      
            this.calendars=data;
            console.log("warningSetting getCalendars:",this.calendars);
          }          
          
        })
        .catch((err) => {
          this.spinning = false;
          console.log(err);
        });
    },
    // 获取邮件发送时间表列表方法
    getMailSchedule() {
      this.spinning = true;
      getMailSchedule()
        .then((res) => {
          this.spinning = false;
          let { data } = res;
          this.list.length=0;
          console.log("warningSetting getMailSchedule:",data);
          if(data!=null&&data.children!=null){     
            if(data.children.length>0)    
              this.list=data.children;
            console.log("warningSetting list:",this.list);
          }          
          
        })
        .catch((err) => {
          this.spinning = false;
          console.log(err);
        });
    }, 
    
    
  },
  components: {
    "my-tabletitle": tabletitle,
    "my-headertitle": headerTitle,
    "my-terminalEditor": terminalEditor,
    "my-devcieContact":deviceContact,
  },
  mounted() {
    // 进入页面时调用获取邮件发送时间表列表方法
    this.getMailSchedule();     
    //取发送日历信息
    this.getCalendars();   
    //取用户列表
    this.getUserSelectList();

    const user = JSON.parse(this.$store.getters.user);
    console.log("warningSettingModel mounted user:",user);
      if(user){
        this.warningSetting.mailUser=user.loginName;
      }
      if(this.warningSetting.equipmentName && this.warningSetting.type&&this.warningSetting.pointID){          
        this.warningSetting.dvChooseList=[];        
        let signal={signalNo:this.warningSetting.pointID,
          signalName:this.warningSetting.equipmentName,
          signalKind:this.warningSetting.type,
        }
        this.typeDesc = this.warningSetting.type;
        this.warningSetting.dvChooseList.push(signal);
      }
  },
};
</script>

<style scoped>
#warningSettingModel {
  width: 100%;
  /* height: 600px;   */
  overflow: hidden;
}
.main {
  width: 100%;
  padding: 10px 20px;
  overflow: auto;
}
.main_tab{
  width: 100%;
  overflow: auto;
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap; */
}
.divider{
  font-weight:bold;
}
button {
  width: 100px;
  margin-right: 15px;
}
</style>